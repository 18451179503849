import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Thuis pagina van ybzconsulting.nl" />
    <h1>Welkom bij ybzconsulting.nl</h1>
      <p>Wij werken aan onze webpagina's. Kom alsjeblieft over een tijdje terug om te kijken naar het resultaat.</p>
      <p>ybzconsulting is geregistreerd bij de Kamer van Koophandel in 's-Hertogenbosch. Wij richten ons met name op het leveren van IT consultancy diensten, Interim management capaciteit en Blockchain & Distributed Ledger diensten.</p>
  </Layout>
)

export default IndexPage
